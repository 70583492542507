import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";


const ContactForm = () => {
    // Initialize the React Hook Form
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [isSuccess, setSuccess] = useState(false)
    const [loading, setLoading] = useState(false)

    // Handle form submission
    const onSubmit = formValues => {
        setLoading(true)
        var message = `Address : ${formValues.address},\nContact Method : ${formValues.contactMethod},\nMessage : ${formValues.message},`
        formValues.message = message
        axios.post('https://ejnarstudios.com/ej-mailer/api/send-mail', formValues).then(response => {
            if (response.data.status) {
                setSuccess(true)
            } else {
                setSuccess(false)
            }
        }).finally(() => {
            setLoading(false)
        })
    };

    return (
        <form
            className="contact-panel__form w-100"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
        >
            {
                isSuccess ?
                    <div className="row text-center align-items-center" style={{ minHeight: 400 }}>
                        <div className="col-12">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={100}
                                height={100}
                                aria-hidden="true"
                                className="d-block mx-auto"
                                viewBox="0 0 64 64"
                            >
                                <circle cx={32} cy={32} r={30} fill="#4bd37b" />
                                <path fill="#fff" d="M46 14 25 35.6l-7-7.2-7 7.2L25 50l28-28.8z" />
                            </svg>
                            <h2 className="mb-2 mt-5">Thanks for reaching out!</h2>
                            <p className="text-secondary lead fw-bold">We’ve received your inquiry and will get back to you within 24 hour</p>
                        </div>
                    </div>
                    :
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <h4 className="contact-panel__title">Request A Quote</h4>
                        </div>
                        {/* /.col-lg-12 */}

                        <div className="col-12">
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Name"
                                    {...register('name', { required: 'Name is required' })}
                                />
                                {errors.name && <small className="text-danger">{errors.name.message}</small>}
                            </div>
                        </div>
                        {/* /.col-12 */}

                        <div className="col-12">
                            <div className="form-group">
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Email"
                                    {...register('email', {
                                        required: 'Email is required',
                                        pattern: {
                                            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                            message: 'Invalid email address'
                                        }
                                    })}
                                />
                                {errors.email && <small className="text-danger">{errors.email.message}</small>}
                            </div>
                        </div>
                        {/* /.col-12 */}

                        <div className="col-12">
                            <div className="form-group">
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Mobile"
                                    {...register('mobile', { required: 'Mobile is required', })}
                                />
                                {errors.mobile && <small className="text-danger">{errors.mobile.message}</small>}
                            </div>
                        </div>
                        {/* /.col-12 */}

                        <div className="col-12">
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Address"
                                    {...register('address', { required: 'Address is required' })}
                                />
                                {errors.address && <small className="text-danger">{errors.address.message}</small>}
                            </div>
                        </div>
                        {/* /.col-12 */}

                        <div className="col-12">
                            <div className="form-group">
                                <textarea
                                    className="form-control"
                                    placeholder="Message"
                                    {...register('message')}
                                />
                            </div>
                        </div>
                        {/* /.col-12 */}

                        <div className="col-12">
                            <span className="font-weight-bold color-heading d-block mb-15 mt-10">
                                Preferred Contact Method
                            </span>
                            <div className="d-flex">
                                <div className="form-group input-radio mr-30">
                                    <label className="label-radio">
                                        All
                                        <input
                                            type="radio"
                                            value="all"
                                            {...register('contactMethod', { required: 'Preferred contact method is required' })}
                                        />
                                        <span className="radio-indicator" />
                                    </label>
                                </div>
                                {/* /.input-radio */}
                                <div className="form-group input-radio mr-30">
                                    <label className="label-radio">
                                        Email
                                        <input
                                            type="radio"
                                            value="email"
                                            {...register('contactMethod')}
                                        />
                                        <span className="radio-indicator" />
                                    </label>
                                </div>
                                {/* /.input-radio */}
                                <div className="form-group input-radio">
                                    <label className="label-radio">
                                        Phone
                                        <input
                                            type="radio"
                                            value="phone"
                                            {...register('contactMethod')}
                                        />
                                        <span className="radio-indicator" />
                                    </label>
                                </div>
                                {/* /.input-radio */}
                            </div>
                            {errors.contactMethod && <small className="text-danger">{errors.contactMethod.message}</small>}

                            <button
                                type="submit"
                                disabled={loading}
                                className="btn btn__secondary btn__block"
                            >
                                <i className="icon-arrow-right" /> <span>{loading ? 'Submitting...' : 'Submit Request'}</span>
                            </button>
                            <br />
                            <br />
                        </div>
                        {/* /.col-12 */}
                    </div>
            }
            {/* /.row */}
        </form>
    );
};

export default ContactForm;
