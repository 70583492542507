
import axios from 'axios';
export const baseUrl = `https://ej-projects.vercel.app/api/web`;

export const getHomeBanners = async () => {
    const response = await axios.post(baseUrl, {
        moduleName: "suriearn_banners",
        sorting: [
            { id: 'order_by', desc: 1 }
        ]
    })

    return response.data;
};

export const getAllServiceCategories = async () => {
    let options = {
        moduleName: "suriearn_servicescategories",
        sorting: [
            { id: 'order_by', desc: 1 }
        ]
    }

    const response = await axios.post(baseUrl, options)

    return response.data;
};

export const getAllServiceCategoriesBySlug = async (slug) => {
    let options = {
        moduleName: "suriearn_servicescategories",
        filters: [
            {
                value: slug,
                id: "slug"
            }
        ]
    }
    const response = await axios.post(baseUrl, options)
    return response.data;
};

export const getFeaturesBenefits = async (value) => {
    let options = {
        moduleName: "suriearn_features_and_beneftheirs",
        filters: [
            {
                value,
                id: "service_category_id"
            }
        ]
    }
    const response = await axios.post(baseUrl, options)
    return response.data;
};

export const getServicesByCategory = async (value) => {
    let options = {
        moduleName: "suriearn_services",
        filters: [
            {
                value,
                id: "service_category_id"
            }
        ]
    }
    const response = await axios.post(baseUrl, options)
    return response.data;
};


export const getProjects = async () => {
    const response = await axios.post(baseUrl, {
        moduleName: "suriearn_projects",
        sorting: [
            { id: 'order_by', desc: 1 }
        ]
    })

    return response.data;
};
