import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import { useFetch } from '../hooks';
import { getAllServiceCategories } from '../services';

const Services = () => {
    const { data } = useFetch({ queryKey: ['ServiceCategories'], queryFn: getAllServiceCategories });

    const settings = {
        slidesToShow: 4,
        slidesToScroll: 4,
        arrows: true,
        dots: true,
        responsive: [{
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        }, {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
    }
    return (
        <section className="services-layout2 pt-120 " style={{ background: 'url(/assets/images/backgrounds/5.jpg)' }}>
            <div className="container">
                <div className="row mb-70">
                    <div className="col-12">
                        <h2 className="heading__subtitle color-primary">
                            Making Tomorrow Different Today.
                        </h2>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-5">
                        <h3 className="heading__title color-white ">
                            A Leading supplier of solar
                            Panels for Residential and Commercial eco-Concious client's
                        </h3>
                    </div>
                    {/* /col-lg-5 */}
                    <div className="col-sm-12 col-md-12 col-lg-6 offset-lg-1">
                        <p className="heading__desc font-weight-bold color-gray mb-30 text-justify">
                            Our goal is to transform how we power our homes and businesses by simplifying, ensuring reliability, and reducing the costs of solar energy. We dedicate ourselves to delivering top-tier rooftop solar solutions, empowering our customers to shape their energy destiny and support a more sustainable and verdant Earth.
                        </p>
                        <div className="d-flex align-items-center">
                            <a
                                href="#contact-us"
                                className="btn btn__primary btn__primary-style2 mr-30"
                            >
                                <i className="icon-arrow-right" />
                                <span>Request A Quote</span>
                            </a>
                           
                        </div>
                    </div>
                    {/* /.col-lg-6 */}
                </div>
                {/* /.row */}
                <div className="row">
                    <div className="col-12">
                        <Slider {...settings}
                            className="carousel-arrows-light"
                        >
                            {
                                data?.data?.map(item => (
                                    <div key={item._id} className="service-item">
                                        <div className="service__img">
                                            <LazyLoadImage
                                                height={180}
                                                alt={item?.title}
                                                effect="blur"
                                                src={item.image.url}
                                                style={{ objectFit: 'cover' }}
                                            />
                                        </div>
                                        {/* /.service__img */}
                                        <div className="service__body">
                                            <h4 className="service__title">{item.title}</h4>
                                            <p className="service__desc">{item.description.substring(0, 80)}...</p>
                                            <Link
                                                to={`/service/${item.slug}`}
                                                className="btn btn__secondary btn__outlined btn__custom"
                                            >
                                                <span>Read More</span>
                                                <i className="icon-arrow-right" />
                                            </Link>
                                        </div>
                                        {/* /.service__body */}
                                    </div>
                                ))
                            }
                        </Slider>
                        {/* /.carousel*/}
                    </div>
                    {/* /.col-12 */}
                </div>
                {/* /.row */}
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-5">
                        <p className="read-note__text">
                            <strong className="color-white">
                                We drive the transition to more sustainable, reliable &amp;
                                affordable energy systems,{" "}
                            </strong>
                            <a
                                href="#"
                                className="text-underlined-primary color-primary font-weight-bold"
                            >
                                <span>Find Your Solution </span>{" "}
                                <i className="icon-arrow-right" />
                            </a>
                        </p>
                    </div>
                    {/* /.col-lg-5 */}
                </div>
                {/* /.row */}
            </div>
            {/* /.container */}
        </section>
    )
}

export default Services