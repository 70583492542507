import { keepPreviousData } from '@tanstack/react-query';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useParams } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import FeaturesAndBenefits from '../components/FeaturesAndBenefits';
import PageLoader from '../components/PageLoader';
import ServiceCards from '../components/ServiceCards';
import { useFetch } from '../hooks';
import { getAllServiceCategoriesBySlug } from '../services';

export default function Service() {
    const { name } = useParams()
    const { data, error, isFetching, isPlaceholderData } = useFetch({
        queryKey: ['ServiceCategoryView', name],
        queryFn: () => getAllServiceCategoriesBySlug(name),
        placeholderData: keepPreviousData,
    });

    if (isFetching && isPlaceholderData) return <PageLoader />
    if (error) return <div>Error: {error.message}</div>;
    const service = data?.data[0]

    return (
        <div>
            <LazyLoadImage
                alt={service?.title}
                className='service-banner bg-secondary'
                effect="blur"
                width={'100%'}
                src={service?.image.url}
            />

            <section className="features-layout2 pt-120  text-justify">
                <div className="px-lg-5 px-3">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-4">
                            <h3 className="slide__title">{service?.title}</h3>
                            <p className=" mb-30 lead">{service?.description}</p>
                            <HashLink to="#services"
                                className="btn btn__primary btn__outlined btn__custom mb-40"
                            >
                                <i className="icon-arrow-right" />
                                <span>Explore All Features!</span>
                            </HashLink>
                        </div>
                        {/* /col-lg-5 */}
                        <div className="col-sm-12 col-md-12 col-lg-8">
                            {
                                service?._id ?
                                    <FeaturesAndBenefits categoryId={service?._id} />
                                    : null
                            }
                            {/* /.row */}
                        </div>
                        {/* /.col-lg-6 */}
                    </div>
                    {/* /.row */}
                </div>
                {/* /.container */}
            </section>
            {
                service?._id ?
                    <ServiceCards categoryId={service?._id} />
                    : null
            }
        </div>
    )
}
