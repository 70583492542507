import React from 'react';

const Clients = () => {
    const sliderSettings = {
        slidesToShow: 3,
        arrows: false,
        dots: false,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2
                }
            }
        ]
    };

    return (
        <section className="clients clients-layout2 border-bottom py-0">
            <div className="container">

                <div className="col-sm-12 col-md-12 col-lg-6">
                    <strong className="text-center d-block py-5">
                        Receive an accurate quote within 24 hours when you fill out the
                        form on this page. Or, give us a call:
                        <a href="tel:919363537757" className="text-underlined-primary">
                            (+91) 93635 37757
                        </a>
                    </strong>
                </div>
                {/* /.col-lg-6 */}
                {/* /.row */}
            </div >
            {/* /.container */}
        </section >
    )
}

export default Clients