import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { useFetch } from '../hooks';
import { getAllServiceCategories } from '../services';

const Header = () => {
    const { data } = useFetch({ queryKey: ['ServiceCategories'], queryFn: getAllServiceCategories });

    return (
        <header className="header header-layout1">
            <div className="header-topbar">
                <div className="container-fluid px-lg-5">
                    <div className="row align-items-center">
                        <div className="col-12">
                            <div className="d-flex align-items-center justify-content-between">
                                <ul className="contact__list d-flex flex-wrap align-items-center list-unstyled mb-0">
                                    <li>
                                        <i className="icon-mail" />
                                        <a href="mailto:info@suriearn.com">
                                            Email: info@suriearn.com
                                        </a>
                                    </li>
                                    <li>
                                        <i className="icon-clock" />
                                        <a href="#">Mon - Fri: 8:00 am - 7:00 pm</a>
                                    </li>
                                </ul>
                                {/* /.contact__list */}
                                <div className="d-flex align-items-center">
                                    <ul className="social-icons list-unstyled mb-0 mr-20">
                                        <li>
                                            <a href="https://www.instagram.com/suriearn/" target='_blank'>
                                                <i className="fab fa-instagram" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.facebook.com/suriearn/" target='_blank'>
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.youtube.com/@SuriearnSmartSolarSystems" target='_blank'>
                                                <i className="fab fa-youtube" />
                                            </a>
                                        </li>
                                    </ul>
                                    <div className='mr-2'>|</div>
                                    <div>
                                        <i className="fa fa-map-marked-alt mr-1 color-primary" />
                                        <a target='_blank' href="https://maps.app.goo.gl/jfznj7fXW115k63CA" className="color-primary">
                                            Get Directions
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* /.col-12 */}
                    </div>
                    {/* /.row */}
                </div>
                {/* /.container */}
            </div>
            {/* /.header-top */}
            <nav className="navbar navbar-expand-lg sticky-navbar">
                <div className="container-fluid px-lg-5">
                    <HashLink to="/#" className="navbar-brand">
                        <img
                            src="/assets/images/logo/logo.png"
                            className="logo"
                            alt="logo"
                            width={110}
                        />
                    </HashLink>
                    <button className="navbar-toggler" type="button">
                        <span className="menu-lines">
                            <span />
                        </span>
                    </button>
                    <div className="collapse navbar-collapse" id="mainNavigation">
                        <ul className="navbar-nav">
                            <li className="nav__item">
                                <HashLink to="/#" className="nav__item-link">
                                    Home
                                </HashLink>
                            </li>
                            <li className="nav__item">
                                <HashLink to="/#about-us" className="nav__item-link">
                                    About us
                                </HashLink>
                            </li>
                            <li className="nav__item drop-down">
                                <a href="#" className="nav__item-link">
                                    Services
                                </a>
                                <ul className='drop-down-items'>
                                    {data?.data?.map(item => (
                                        <HashLink key={item._id} to={`/service/${item.slug}`}>{item.title}</HashLink>
                                    ))}
                                </ul>
                            </li>
                            <li className="nav__item">
                                <HashLink to="/#projects" className="nav__item-link">
                                    Projects
                                </HashLink>
                            </li>
                            <li className="nav__item">
                                <HashLink to="/#contact-us" className="nav__item-link">
                                    Contact us
                                </HashLink>
                            </li>
                        </ul>
                        {/* /.navbar-nav */}
                        <button className="close-mobile-menu d-block d-lg-none">
                            <i className="fas fa-times" />
                        </button>
                    </div>
                    {/* /.navbar-collapse */}
                    <div className="contact__number d-none d-xl-flex align-items-center">
                        <i className="fa fa-phone-square-alt" />
                        <a href="tel:919363537757">93635 37757</a>
                    </div>
                    <ul className="navbar-actions d-none d-xl-flex align-items-center list-unstyled mb-0">
                        <li>
                            <HashLink to="/#contact-us" className="btn btn__primary">
                                <span>Request A Quote</span>
                                <i className="icon-arrow-right" />
                            </HashLink>
                        </li>
                    </ul>
                    {/* /.navbar-actions */}
                </div>
                {/* /.container */}
            </nav>
            {/* /.navabr */}
        </header>
    )
}

export default Header