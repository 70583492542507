import React from 'react';
import { Link } from 'react-router-dom';
import { useFetch } from '../hooks';
import { getAllServiceCategories } from '../services';

const Footer = () => {
    const { data } = useFetch({ queryKey: ['ServiceCategories'], queryFn: getAllServiceCategories });

    return (
        <div>
            <footer className="footer">
                <div className="footer-primary">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3 footer-widget footer-widget-contact">
                                <h6 className="footer-widget-title">Quick Contact</h6>
                                <div className="footer-widget-content">
                                    <p className="mb-20">
                                        If you have any questions or need help, feel free to contact
                                        with our team.
                                    </p>
                                    <div className="contact__number d-flex align-items-center mb-30">
                                        <i className="fa fa-phone-square-alt" />
                                        <a href="tel:919363537757" className="color-primary">
                                            93635 37757
                                        </a>
                                    </div>
                                    {/* /.contact__numbr */}
                                    <p className="mb-20">
                                        Admin & Sales Office: 3rd floor, New no 4: Old no 19, Bhuvaneswari complex, Dr
                                        Thomas Rd, Chennai 600017.
                                    </p>
                                    <a href="https://maps.app.goo.gl/jfznj7fXW115k63CA" target='_blank' className="btn__location">
                                        <i className="fa fa-map-marked-alt mr-1" />
                                        <span>Get Directions</span>
                                    </a>
                                </div>
                                {/* /.footer-widget-content */}
                            </div>
                            {/* /.col-xl-3 */}
                            <div className="col-6 col-sm-6 col-md-6 col-lg-2 col-xl-2 footer-widget footer-widget-nav">
                                <h6 className="footer-widget-title">Company</h6>
                                <div className="footer-widget-content">
                                    <nav>
                                        <ul className="list-unstyled">
                                            <li>
                                                <a href="/#">Home</a>
                                            </li>
                                            <li>
                                                <a href="/#about-us">About Us</a>
                                            </li>
                                            <li>
                                                <a href="/#projects">Our Projects</a>
                                            </li>
                                            <li>
                                                <a href="/#contact-us">Contacts Us</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                                {/* /.footer-widget-content */}
                            </div>
                            {/* /.col-xl-2 */}
                            <div className="col-6 col-sm-6 col-md-6 col-lg-2 col-xl-2 footer-widget footer-widget-nav">
                                <h6 className="footer-widget-title">Services</h6>
                                <div className="footer-widget-content">
                                    <nav>
                                        <ul className="list-unstyled">
                                            {data?.data?.map(item => (
                                                <li key={item._id}>
                                                    <Link to={`/service/${item.slug}`}>{item.title}</Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </nav>
                                </div>
                                {/* /.footer-widget-content */}
                            </div>
                            {/* /.col-xl-2 */}
                            {/* <div className="col-6 col-sm-6 col-md-6 col-lg-2 col-xl-2 footer-widget footer-widget-nav">
                                <h6 className="footer-widget-title">Support</h6>
                                <div className="footer-widget-content">
                                    <nav>
                                        <ul className="list-unstyled">
                                            <li>
                                                <a href="#">Terms &amp; Conditions</a>
                                            </li>
                                            <li>
                                                <a href="#">Shipping Policy</a>
                                            </li>
                                            <li>
                                                <a href="#">Delivery Tips</a>
                                            </li>
                                            <li>
                                                <a href="#">Returns</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                        </div> */}
                            {/* /.col-xl-2 */}
                            <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4 footer-widget footer-widget-align-right">
                                <h6 className="footer-widget-title">Products Catalogue</h6>
                                <div className="footer-widget-content">
                                    <a
                                        href="./Solar Broucher.pdf"
                                        download="./Solar Broucher.pdf"
                                        className="btn btn__primary btn__primary-style2 btn__download mb-60"
                                    >
                                        <i className="icon-download" />
                                        <span>Download PDF</span>
                                    </a>
                                    <ul className="social-icons list-unstyled">
                                        <li>
                                            <a href="https://www.instagram.com/suriearn/" target='_blank'>
                                                <i className="fab fa-instagram" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.facebook.com/suriearn/" target='_blank'>
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.youtube.com/@SuriearnSmartSolarSystems" target='_blank'>
                                                <i className="fab fa-youtube" />
                                            </a>
                                        </li>
                                    </ul>
                                    {/* /.social-icons */}
                                </div>
                                {/* /.footer-widget-content */}
                            </div>
                            {/* /.col-xl-3 */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container */}
                </div>
                {/* /.footer-primary */}
                <div className="footer-copyrights">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 d-flex justify-content-between">
                                <p className="mb-0">
                                    <span className="color-gray">
                                        © 2024 Suriearn, All Rights Reserved. developed by
                                    </span>
                                    <a href="http://ejnarstudios.com"> ejnarstudios</a>
                                </p>
                                <p className="mb-0">
                                    <span className="color-gray">
                                        Powered by | Sacred Trinity Digiagency
                                    </span>
                                </p>
                            </div>
                            {/* /.col-lg-12 */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container */}
                </div>
                {/* /.footer-copyrights*/}
            </footer >
            {/* /.Footer */}
            <button button id="scrollTopBtn" >
                <i className="fas fa-long-arrow-alt-up" />
            </button >
        </div >
    )
}

export default Footer