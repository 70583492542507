import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useFetch } from '../hooks';
import { getFeaturesBenefits } from '../services';

export default function FeaturesAndBenefits({ categoryId }) {
    const { data, error } = useFetch({
        queryKey: ['FeaturesAndBenefits', categoryId],
        queryFn: () => getFeaturesBenefits(categoryId)
    });
    if (error) return <div>Error: {error.message}</div>;
    return (
        <div className='row justify-content-center text-lg-left text-center'>
            <div className="col-lg-6">
                <h4 className="slide__title">Features</h4>
                <div className="row">
                    {
                        data?.data?.filter(item => item.type === "FEATURES").map(item => (
                            <div key={item._id} className="col-sm-6">
                                <div className="feature-item">
                                    <div className="feature__icon">
                                        <LazyLoadImage
                                            width={60}
                                            alt={item?.title}
                                            effect="blur"
                                            src={item.image.url}
                                        />
                                    </div>
                                    <h4 className="feature__title">{item.title}</h4>
                                    <p className="feature__desc">{item.description}</p>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className='border-left px-3 d-lg-block d-none'></div>
            <div className='col-12 d-block d-lg-none'>
                <hr />
            </div>
            <div className="col-lg-5">
                <h4 className="slide__title">Benefits</h4>
                <div className="row">
                    {
                        data?.data?.filter(item => item.type === "BENEFITS").map(item => (
                            <div key={item._id} className="col-sm-6">
                                <div className="feature-item">
                                    <div className="feature__icon">
                                        <LazyLoadImage
                                            width={60}
                                            alt={item?.title}
                                            effect="blur"
                                            src={item.image.url}
                                        />
                                    </div>
                                    <h4 className="feature__title">{item.title}</h4>
                                    <p className="feature__desc">{item.description}</p>
                                </div>
                                {/* /.feature-item */}
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>

    )
}
