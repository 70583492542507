import React from 'react'
import ContactForm from './ContactForm'

const CTAForm = () => {
    return (
        <>
            <section className="cta-layout1 pt-0" >
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-8 mx-auto ">
                            <div className="cta__item d-md-flex text-md-left text-center shadow border">
                                <div className="cta__icon  mx-auto custom-icon">
                                    <i className="icon-energy" />
                                </div>
                                {/* /.cta__icon */}
                                <div className="cta__body mx-auto ">
                                    <h4 className="cta__title">Design &amp; Shipping</h4>
                                    <p className="cta__desc">
                                        We work together with you to develop and implement a system that suits your utility consumption and equipment selection needs.
                                    </p>
                                    <a href="#" className="btn btn__link btn__primary d-inline-flex mr-auto">
                                        <i className="icon-arrow-right" />
                                        <span>Schedule A Visit</span>
                                    </a>
                                </div>
                                {/* /.cta__body */}
                            </div>
                            {/* /.cta__item */}
                        </div>
                    </div>
                    {/* /.cta */}
                    <div className="row">
                        <div className="col-12">
                            <p className="text__link text-center mb-0">
                                Discover independence through the power of solar,
                                <a href="#contact-us">
                                    <span> Request a Quote</span>{" "}
                                    <i className="icon-arrow-right" />
                                </a>
                            </p>
                        </div>
                        {/* /.col-12 */}
                    </div>
                    {/* /.row */}
                </div>
                {/* /.container */}
            </section>
            <section id='contact-us' className="contact-layout2 pb-0 bg-overlay bg-overlay-primary-gradient">
                <div className="bg-img">
                    {/* <img src="/assets/images/banners/2.jpg" alt="" /> */}
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
                            <div className="col-inner">
                                <div className="heading-layout2 heading-light mb-60">
                                    <h2 className="heading__subtitle">
                                        Save Money, Save The Environment!
                                    </h2>
                                    <h3 className="heading__title">
                                        Providing value to our clients through ongoing product &amp;
                                        innovation.
                                    </h3>
                                    <p className="heading__desc  text-justify">Our focus is on creating greener, solar-based solutions tailored to meet the needs of both homes and businesses. By harnessing the power of sustainable energy, we not only enhance efficiency and reduce costs but also contribute to a cleaner, more sustainable future. Partner with us to embrace innovation and make a positive impact on the environment.</p>
                                </div>
                                {/* /.heading */}
                                <div className="row fancybox-light text-center">
                                    {/* fancybox item #1 */}
                                    <div className="col-sm-4">
                                        <div className="fancybox-item">
                                            <div className="fancybox__icon">
                                                <i className="icon-biosphere2" />
                                            </div>
                                            {/* /.fancybox-icon */}
                                            <div className="fancybox__content">
                                                <h4 className="fancybox__title mb-0">
                                                    Environmental Sensitivity
                                                </h4>
                                            </div>
                                            {/* /.fancybox-content */}
                                        </div>
                                        {/* /.fancybox-item */}
                                    </div>
                                    {/* /.col-sm-4 */}
                                    {/* fancybox item #2 */}
                                    <div className="col-sm-4">
                                        <div className="fancybox-item">
                                            <div className="fancybox__icon">
                                                <i className="icon-tube" />
                                            </div>
                                            {/* /.fancybox-icon */}
                                            <div className="fancybox__content">
                                                <h4 className="fancybox__title mb-0">
                                                    Personalised Solutions
                                                </h4>
                                            </div>
                                            {/* /.fancybox-content */}
                                        </div>
                                        {/* /.fancybox-item */}
                                    </div>
                                    {/* /.col-sm-4 */}
                                    {/* fancybox item #3 */}
                                    <div className="col-sm-4">
                                        <div className="fancybox-item">
                                            <div className="fancybox__icon">
                                                <i className="icon-electric-charge" />
                                            </div>
                                            {/* /.fancybox-icon */}
                                            <div className="fancybox__content">
                                                <h4 className="fancybox__title mb-0">
                                                    Performance Measures
                                                </h4>
                                            </div>
                                            {/* /.fancybox-content */}
                                        </div>
                                        {/* /.fancybox-item */}
                                    </div>
                                    {/* /.col-sm-4 */}
                                </div>
                                {/* /.row */}
                            </div>
                        </div>
                        {/* /.col-xl-6 */}
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 pb-3">
                            <div className="contact-panel">
                                <ContactForm />
                            </div>
                        </div>
                        {/* /.col-xl-6 */}
                    </div>
                    {/* /.row */}
                </div>
                {/* /.container */}
            </section>
        </>
    )
}

export default CTAForm