import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useFetch } from '../hooks';
import { getServicesByCategory } from '../services';

export default function ServiceCards({ categoryId }) {
    const { data, error } = useFetch({
        queryKey: ['ServiceCards', categoryId],
        queryFn: () => getServicesByCategory(categoryId)
    });
    if (error) return <div>Error: {error.message}</div>;
    return (
        <div className="container pb-lg-5 pb-3" id='services'>
            <h4 className="slide__title text-center">Services Include</h4>
            <div className='row'>
                {
                    data?.data?.map(item => (
                        <div key={item._id} className="col-md-4">
                            <div className="card  border-0">
                                <div className="service__img">
                                    <LazyLoadImage
                                        width={'100%'}
                                        alt={item?.title}
                                        effect="blur"
                                        src={item.image.url}
                                        style={{ objectFit: 'cover' }}
                                    />
                                </div>
                                <h6 className="card-title mt-3 mb-0">{item.title}</h6>
                                <p className="card-content">{item.description}</p>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
