import React, { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Footer from './components/Footer'
import Header from './components/Header'

export default function Layout() {
    const { pathname } = useLocation()
    useEffect(() => window.scroll(0, 0), [pathname])
    return (
        <>
            <Header />
            <Outlet />
            <Footer />
        </>
    )
}
