import React from 'react'

const Features = () => {
    return (
        <section className="features-layout2 pt-120  text-justify">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-5">
                        <h2 className="heading__subtitle color-primary">
                            Making Tomorrow Different Today.
                        </h2>
                        <h1 className="slide__title">
                            Why Choose Us?
                        </h1>
                        <p className=" mb-30 lead">
                            Expertise: Our team is composed of highly experienced solar energy professionals. We possess the necessary knowledge and expertise to design and install rooftop solar systems that optimize energy production and cost savings.
                        </p>
                    </div>
                    {/* /col-lg-5 */}
                    <div className="col-sm-12 col-md-12 col-lg-6 offset-lg-1">
                        <div className="row">
                            {/* Feature item #1 */}
                            <div className="col-sm-6">
                                <div className="feature-item">
                                    <div className="feature__icon">
                                        <i className="icon-hydro-power3" />
                                    </div>
                                    <h4 className="feature__title">Quality Products</h4>
                                    <p className="feature__desc">
                                        We collaborate with leading
                                        manufacturers to supply top-tier
                                        solar panels, inverters, and
                                        mounting systems. Our products are engineered to endure the extreme weather elements & provide outstanding performance for the long term.
                                    </p>
                                </div>
                                {/* /.feature-item */}
                            </div>
                            {/* /.col-sm-6 */}
                            {/* Feature item #2 */}
                            <div className="col-sm-6">
                                <div className="feature-item">
                                    <div className="feature__icon">
                                        <i className="icon-biosphere" />
                                    </div>
                                    <h4 className="feature__title">Exceptional Customer Service</h4>
                                    <p className="feature__desc">
                                        Our dedication to exceptional
                                        customer service is unwavering
                                        throughout the entire process. We
                                        are devoted to addressing your
                                        inquiries, resolving any issues, and providing continuous support and maintenance to guarantee your absolute satisfaction
                                    </p>
                                </div>
                                {/* /.feature-item */}
                            </div>
                            {/* /.col-sm-6 */}
                            {/* Feature item #3 */}
                            <div className="col-sm-6">
                                <div className="feature-item">
                                    <div className="feature__icon">
                                        <i className="icon-eco-house" />
                                    </div>
                                    <h4 className="feature__title">Customized Solutions</h4>
                                    <p className="feature__desc">
                                        Recognizing the uniqueness of
                                        each home and business, we
                                        customize our solar solutions to
                                        align with the individual needs and
                                        specifications of our clients. No
                                        matter the size of your residential or commercial property, we can design a system that accommodates your space and pocket friendly budget.
                                    </p>
                                </div>
                                {/* /.feature-item */}
                            </div>
                            {/* /.col-sm-6 */}
                            {/* Feature item #4 */}
                            <div className="col-sm-6">
                                <div className="feature-item">
                                    <div className="feature__icon">
                                        <i className="icon-energy2" />
                                    </div>
                                    <h4 className="feature__title">Seamless Installation</h4>
                                    <p className="feature__desc">
                                        Our team manages all facets of the
                                        installation process, from the
                                        preliminary site evaluation to the
                                        comprehensive final inspection.
                                    </p>
                                    <p>Our goal is to streamline the installation experience, minimizing
                                        inconvenience so that you can
                                        benefit from solar energy at the
                                        earliest opportunity.</p>
                                </div>
                                {/* /.feature-item */}
                            </div>
                            {/* /.col-sm-6 */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.col-lg-6 */}
                </div>
                {/* /.row */}
            </div>
            {/* /.container */}
        </section>
    )
}

export default Features