import React from 'react'

export default function PageLoader() {
    return (
        <div>
            <div className="loading-container">
                <img
                    src="/assets/images/logo/logo.png"
                    className="logo mb-4"
                    alt="logo"
                    width={110}
                />
                <div className="spinner"></div>
                <p className='my-3'>Loading, please wait...</p>
            </div>
        </div>
    )
}
