import React from 'react';
import { HashLink } from 'react-router-hash-link';
import Slider from 'react-slick';
import { useFetch } from '../hooks';
import { getProjects } from '../services';

const Portfolio = () => {
    const { data } = useFetch({ queryKey: ['Projects'], queryFn: getProjects });

    const sliderSettings = {
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: true,
        dots: true,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <section className="services-layout2 pt-120" id='projects' style={{ background: 'url(/assets/images/backgrounds/5.jpg)' }}>
            <div className="container">
                <div className="row mb-60">
                    <div className="col-12">
                        <h2 className="heading__subtitle color-primary">
                            Improving the performance of solar energy.
                        </h2>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-5">
                        <h3 className="heading__title color-white">
                            Latest projects, solutions and energy supplies
                        </h3>
                    </div>
                    {/* /col-lg-5 */}
                    <div className="col-sm-12 col-md-12 col-lg-6 offset-lg-1">
                        <div><b className='color-primary lead'>Considering a switch to solar?</b></div>
                        <p className="heading__desc font-weight-bold color-gray mb-30">
                            Reach out to us now to arrange a complimentary consultation and discover
                            the advantages of rooftop solar panels. Let's work together towards a
                            cleaner, more sustainable future for all generations.
                        </p>
                        <a href="#contact-us" className="btn btn__white btn__outlined">
                            <i className="icon-arrow-right" />
                            <span>Request A Quote</span>
                        </a>
                    </div>
                    {/* /.col-lg-6 */}
                </div>
                {/* /.row */}
                <div className="row">
                    <div className="col-12">
                        <div className="carousel-container">
                            {
                                data?.data ?
                                    <Slider {...sliderSettings}
                                        className="slick-carousel carousel-arrows-light carousel-dots-light"
                                    >
                                        {
                                            data.data.map(item => (
                                                <div key={item._id} className="portfolio-item">
                                                    <div className="portfolio__img">
                                                        <img
                                                            src={item.image.url}
                                                            alt="portfolio img"
                                                        />
                                                    </div>
                                                    {/* /.portfolio-img */}
                                                    <div className="portfolio__body">
                                                        <div className="portfolio__cat">
                                                            <a href="#">{item.hight_light}</a>
                                                        </div>
                                                        {/* /.portfolio-cat */}
                                                        <h4 className="portfolio__title">
                                                            <a href="#">
                                                                {item.title}
                                                            </a>
                                                        </h4>
                                                        <p className="portfolio__desc">
                                                            {item.description}
                                                        </p>
                                                        <HashLink to="/#contact-us"
                                                            className="btn btn__primary btn__sm"
                                                        >
                                                            <i className="icon-arrow-right" />
                                                            <span>Explore More</span>
                                                        </HashLink>
                                                    </div>
                                                    {/* /.portfolio__body */}
                                                </div>
                                            ))
                                        }
                                    </Slider>
                                    : null
                            }
                            {/* /.carousel*/}
                            <a
                                href="#"
                                className="view-projects d-none d-xl-flex"
                            >
                                All Projects
                            </a>
                        </div>
                        {/* /.carousel-container */}
                    </div>
                    {/* /.col-12 */}
                </div>
                {/* /.row */}
            </div>
            {/* /.container */}
        </section>
    )
}

export default Portfolio