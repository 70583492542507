import React from 'react'
import AboutUs from '../components/AboutUs'
import BannerSlider from '../components/BannerSlider'
import CTAForm from '../components/CTAForm'
import Clients from '../components/Clients'
import Features from '../components/Features'
import Portfolio from '../components/Portfolio'
import Services from '../components/Services'
import Testimonials from '../components/Testimonials'

export default function Home() {
    return (
        <>
            <BannerSlider />
            <AboutUs />
            <Services />
            <Features />
            <Portfolio />
            <Testimonials />
            <CTAForm />
            <Clients />
        </>
    )
}
