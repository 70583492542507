import React from 'react'

const Testimonials = () => {
    return (
        <section className="testimonials-layout1 pb-0">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-6 offset-lg-3">
                        <div className="heading text-center mb-40">
                            <h2 className="heading__subtitle color-primary">
                                Customers Doing it Their Way
                            </h2>
                            <h3 className="heading__title">
                                Our Customers Share Their Experiences and Insights
                            </h3>
                        </div>
                        {/* /.heading */}
                    </div>
                    {/* /.col-lg-6 */}
                </div>
                {/* /.row */}
                <div className="row">
                    <div className="col-12">
                        <img src="/assets/images/backgrounds/map2.png" alt="map" />
                        {/* Testimonial #1 */}
                        <div className="testimonial-box">
                            <div className="testimonial__thumb">
                                <img
                                    src="/assets/images/testimonials/thumbs/1.png"
                                    alt="author thumb"
                                />
                                <span className="pulsing-animation pulsing-animation-1" />
                                <span className="pulsing-animation pulsing-animation-2" />
                                <span className="pulsing-animation pulsing-animation-3" />
                            </div>
                            {/* /.testimonial-thumb */}
                            <div className="testimonial__panel">
                                <div className="testimonial__desc">
                                    As one of the world's largest ITService Providers with over
                                    120 engineers and IT support staff are ready to help.
                                </div>
                            </div>
                            {/* /.testimonial-panel */}
                        </div>
                        {/* /. testimonial-box */}
                        {/* Testimonial #2 */}
                        <div className="testimonial-box">
                            <div className="testimonial__thumb">
                                <img
                                    src="/assets/images/testimonials/thumbs/2.png"
                                    alt="author thumb"
                                />
                                <span className="pulsing-animation pulsing-animation-1" />
                                <span className="pulsing-animation pulsing-animation-2" />
                                <span className="pulsing-animation pulsing-animation-3" />
                            </div>
                            {/* /.testimonial-thumb */}
                            <div className="testimonial__panel">
                                <div className="testimonial__desc">
                                    As one of the world's largest ITService Providers with over
                                    120 engineers and IT support staff are ready to help.
                                </div>
                            </div>
                            {/* /.testimonial-panel */}
                        </div>
                        {/* /. testimonial-box */}
                        {/* Testimonial #3 */}
                        <div className="testimonial-box">
                            <div className="testimonial__thumb">
                                <img
                                    src="/assets/images/testimonials/thumbs/3.png"
                                    alt="author thumb"
                                />
                                <span className="pulsing-animation pulsing-animation-1" />
                                <span className="pulsing-animation pulsing-animation-2" />
                                <span className="pulsing-animation pulsing-animation-3" />
                            </div>
                            {/* /.testimonial-thumb */}
                            <div className="testimonial__panel">
                                <div className="testimonial__desc">
                                    As one of the world's largest ITService Providers with over
                                    120 engineers and IT support staff are ready to help.
                                </div>
                            </div>
                            {/* /.testimonial-panel */}
                        </div>
                        {/* /. testimonial-box */}
                        {/* Testimonial #4 */}
                        <div className="testimonial-box testimonial-hover-left">
                            <div className="testimonial__thumb">
                                <img
                                    src="/assets/images/testimonials/thumbs/4.png"
                                    alt="author thumb"
                                />
                                <span className="pulsing-animation pulsing-animation-1" />
                                <span className="pulsing-animation pulsing-animation-2" />
                                <span className="pulsing-animation pulsing-animation-3" />
                            </div>
                            {/* /.testimonial-thumb */}
                            <div className="testimonial__panel">
                                <div className="testimonial__desc">
                                    As one of the world's largest ITService Providers with over
                                    120 engineers and IT support staff are ready to help.
                                </div>
                            </div>
                            {/* /.testimonial-panel */}
                        </div>
                        {/* /. testimonial-box */}
                        {/* Testimonial #5 */}
                        <div className="testimonial-box testimonial-hover-left">
                            <div className="testimonial__thumb">
                                <img
                                    src="/assets/images/testimonials/thumbs/5.png"
                                    alt="author thumb"
                                />
                                <span className="pulsing-animation pulsing-animation-1" />
                                <span className="pulsing-animation pulsing-animation-2" />
                                <span className="pulsing-animation pulsing-animation-3" />
                            </div>
                            {/* /.testimonial-thumb */}
                            <div className="testimonial__panel">
                                <div className="testimonial__desc">
                                    As one of the world's largest ITService Providers with over
                                    120 engineers and IT support staff are ready to help.
                                </div>
                            </div>
                            {/* /.testimonial-panel */}
                        </div>
                        {/* /. testimonial-box */}
                        {/* Testimonial #6 */}
                        <div className="testimonial-box">
                            <div className="testimonial__thumb">
                                <img
                                    src="/assets/images/testimonials/thumbs/1.png"
                                    alt="author thumb"
                                />
                                <span className="pulsing-animation pulsing-animation-1" />
                                <span className="pulsing-animation pulsing-animation-2" />
                                <span className="pulsing-animation pulsing-animation-3" />
                            </div>
                            {/* /.testimonial-thumb */}
                            <div className="testimonial__panel">
                                <div className="testimonial__desc">
                                    As one of the world's largest ITService Providers with over
                                    120 engineers and IT support staff are ready to help.
                                </div>
                            </div>
                            {/* /.testimonial-panel */}
                        </div>
                        {/* /. testimonial-box */}
                        {/* Testimonial #7 */}
                        <div className="testimonial-box">
                            <div className="testimonial__thumb">
                                <img
                                    src="/assets/images/testimonials/thumbs/4.png"
                                    alt="author thumb"
                                />
                                <span className="pulsing-animation pulsing-animation-1" />
                                <span className="pulsing-animation pulsing-animation-2" />
                                <span className="pulsing-animation pulsing-animation-3" />
                            </div>
                            {/* /.testimonial-thumb */}
                            <div className="testimonial__panel">
                                <div className="testimonial__desc">
                                    As one of the world's largest ITService Providers with over
                                    120 engineers and IT support staff are ready to help.
                                </div>
                            </div>
                            {/* /.testimonial-panel */}
                        </div>
                        {/* /. testimonial-box */}
                    </div>
                    {/* /.col-12 */}
                </div>
                {/* /.row */}
            </div>
            {/* /.container */}
        </section>
    )
}

export default Testimonials