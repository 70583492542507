import { keepPreviousData } from '@tanstack/react-query';
import React, { Fragment } from 'react';
import Slider from "react-slick";
import { useFetch } from '../hooks';
import { getHomeBanners } from '../services';
import PageLoader from './PageLoader';

const BannerSlider = () => {

    const { error, data, isFetching, } = useFetch({
        queryKey: ['banners'],
        queryFn: getHomeBanners,
        placeholderData: keepPreviousData,
    });

    const settings = {
        slidesToShow: 1,
        arrows: true,
        dots: true,
        speed: 700,
        fade: true,
        cssEase: "linear"
    }
    if (isFetching) return <PageLoader />
    if (error) return <div>Error: {error.message}</div>;
    return (
        <section className="slider">
            <Slider
                {...settings}
                className=" carousel-arrows-light carousel-dots-light m-slides-0"
            >
                {
                    data?.data?.map(item => (
                        <Fragment key={item._id}>
                            <div className="slide-item align-v-h bg-overlay bg-overlay-2" style={{
                                'background': `linear-gradient(transparent, black), url(${item.image.url})`,
                                'background-size': 'cover',
                                'background-position': 'center',
                            }}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-8">
                                            <div className="slide__body">
                                                <span className="slide__subtitle">
                                                    {item.sub_title}
                                                </span>
                                                <h2 className="slide__title">
                                                    {item.title}
                                                </h2>
                                                <p className="slide__desc">{item.description}</p>
                                                <div className="d-flex">
                                                    <a href="#about-us" className="btn btn__primary mr-30">
                                                        <i className="icon-arrow-right" />
                                                        <span>More about us</span>
                                                    </a>
                                                </div>
                                            </div>
                                            {/* /.slide__body */}
                                        </div>
                                        {/* /.col-xl-8 */}
                                    </div>
                                    {/* /.row */}
                                </div>
                                {/* /.container */}
                            </div>
                        </Fragment>
                    ))
                }

            </Slider>
        </section>
    )
}

export default BannerSlider