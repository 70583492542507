import React from 'react'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Layout from './Layout'
import Home from './pages/Home'
import Service from './pages/Service'

const App = () => {
  return (
    <div className="wrapper">
      <BrowserRouter>
        <Routes>
          <Route Component={Layout}>
            <Route index Component={Home} />
            <Route path='/service/:name' Component={Service} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App